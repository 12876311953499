<de-header
  [headerLinks]="headerLinks"
  [isExternal]="false"
  [siteTitle]="'Portfolio Automation & Reporting'"
  [hamburgerMenuMobile]="true"
  (hamburgerMenuClick)="toggleHamburgerMenuDrawer()"
  (secondaryLogoClick)="navigateHome()"
  [closeSublinksOnClick]="true"
  (navigation)="onNavLinkClick($event)"
>
</de-header>

<de-drawer
  [position]="'left'"
  [(drawerIsOpen)]="headerDrawerIsOpen"
  [drawerGreeting]="drawerGreeting"
  [headerLinks]="headerLinks"
  [resetOnClose]="true"
  (navigation)="onNavLinkClick($event)"
>
</de-drawer>
