import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalLoaderService } from '../global-loader.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
})
export class GlobalLoaderComponent {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoadingValue: boolean;

  constructor(private loaderService: GlobalLoaderService) {
    this.isLoading.subscribe(value => {
      this.isLoadingValue = value;
    })
   }

}
