import { Component, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { WorkerInfo } from './models/worker-info';
import { AuthenticationService } from './services/auth/auth.service';
import { AuthenticatedUser } from './services/auth/authenticated-user';
import { StorageService } from './services/storage.service';
import { ApiService } from './services/api/api.service';
import { Store } from '@ngrx/store';
import { addDeptLeads } from './shared/store/department-leads/department-leads.action';
import { DeptLeads } from './models/dept-leads';
import { addProject } from './shared/store/project/project.action';
import { Project } from './models/project';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser?: AuthenticatedUser;
  emailAddress: string;
  fullName: string;
  userSubs: Subscription;
  currentRoute: string;
  isAdmin: boolean;
  isParUser: boolean;
  roles: any;
  loginUserInfo: WorkerInfo;

  constructor(
    private auth: AuthenticationService,
    private storage: StorageService,
    private apiService: ApiService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.auth.getUserEmail().then((email) => {
      this.emailAddress = email.toLocaleLowerCase();
      console.log('User Email')
      console.log(this.emailAddress)
      this.getUserInfo();
      this.auth.getUsersName().then((name) => (this.fullName = name));
    });
    this.getDetails();
  }

  getDetails(){
    forkJoin([
      this.apiService.getItems("get-dept-leads" , {}),
      this.apiService.getItems('project', {}),
    ]).subscribe((res) => {
      this.store.dispatch(addDeptLeads({departmentLeads : res[0] as DeptLeads[]}));
      this.store.dispatch(addProject({project : res[1] as Project[]}));  
    });
  }

  getUserInfo() {
    this.auth.getLoggedUserInfo$(this.emailAddress).subscribe((res) => {
      // For Header
      this.userSubs = this.auth
      .getCurrentAuthenticatedUser()
      .subscribe((user) => (this.currentUser = user));

      this.isParUser = this.auth.isParUser();

      this.loginUserInfo = this.storage.getLoggedUser()[0];
      if (this.loginUserInfo.manager_level_name == 'Individual Contributor') {
        this.storage.setCurrentManager(
          this.loginUserInfo.manager_source_worker_id
        );
      } else {
        this.storage.setCurrentManager(this.loginUserInfo.source_worker_id);
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubs.unsubscribe();
  }
}
