import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminAccessService {

  adminUsers: string[] = ['nilesh.rawale@duke-energy.com', 'mohammad.zeyauddin@duke-energy.com',
                          'casey.oates@duke-energy.com', 'jon.helton@duke-energy.com',
                          'vedhas.patil@duke-energy.com',
                          'jacquelyn.hall@duke-energy.com', 'kevin.adam@duke-energy.com', 
                          'akshay.sutar@duke-energy.com', 'aniket.mane@duke-energy.com'
                        ];

  getAdminUsers(){
    return this.adminUsers;
  }
}
