<div class="page-container">
  <mat-card class="unauthorized-block">
    <div class="par-logo">
      <img src="../../../../assets/Par-1.png" alt="PAR Logo" height="auto" width="200px">
    </div>
    <div class="message-card">
      <div class="message-title">
        <div de-h3>Unauthorized User</div>
      </div>
      <div class="message">You Need Permission To Access This Site</div>
      <div class="message">
        If Pop-Ups are blocked in your browser or you are not immediately
        redirected, click on the following link to request access to PAR.
      </div>
      <de-button
        class="redirect-link"
        matTooltip="Portfolio Automation & Reporting Request Form"
        matTooltipPosition="above"
        (click)="nonUserClick()"
        >Request Access</de-button
      >
    </div>
  </mat-card>
</div>
