import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';

//generate an app routing module file
const routes: Routes = [
  { path: 'dashboard', loadChildren: () =>
                          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)},
  { path: 'director', loadChildren: () =>
                          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)},
  { path: 'reports', loadChildren: () =>
                          import('./modules/reports/reports.module').then((m) => m.ReportsModule)},
  { path: 'admin', loadChildren: () =>
                          import('./modules/par-admin/par-admin.module').then((m) => m.ParAdminModule)},
  { path: 'project', loadChildren: () =>
                          import('./modules/project/project.module').then((m) => m.ProjectModule)},
  { path: 'worker', loadChildren: () =>
                          import('./modules/worker/worker.module').then((m) => m.WorkerModule)},
  { path: 'help', loadChildren: () =>
                          import('./modules/help-page/par-help-page.module').then((m) => m.ParHelpPageModule)},
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'unauthorized', component: UnauthorizedComponent},

]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,
    { preloadingStrategy: PreloadAllModules,
      useHash: true,
      initialNavigation: isInIframe() ? 'disabled' : undefined,
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}

