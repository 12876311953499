import { NgModule } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
@NgModule({
    declarations: [],
    imports: [
      CommonModule
    ]
  })
export class IconModule {

private path: string = "../../assets/icons";

 constructor(
  private domSanitizer: DomSanitizer,
  public matIconRegistry: MatIconRegistry ) {

  this.matIconRegistry
  .addSvgIcon("pencil-icon", this.setPath(`${this.path}/pencil.svg`))
  .addSvgIcon("x-icon", this.setPath(`${this.path}/x-icon.svg`))
  .addSvgIcon("x-icon-white", this.setPath(`${this.path}/x-icon-white.svg`))
  .addSvgIcon("x-icon-red", this.setPath(`${this.path}/x-icon-red.svg`))
  .addSvgIcon("eye-solid-icon", this.setPath(`${this.path}/eye-solid-blue.svg`))
  .addSvgIcon("icon-check-solid-blue", this.setPath(`${this.path}/check-icon-blue.svg`))
  .addSvgIcon("check-icon-green", this.setPath(`${this.path}/check solid green dark.svg`))
  .addSvgIcon("icon-refresh", this.setPath(`${this.path}/icon-refresh-solid-blue.svg`))
  .addSvgIcon("icon-home-solid-blue", this.setPath(`${this.path}/icon-home-solid-blue.svg`))
  .addSvgIcon("icon-search-solid-blue", this.setPath(`${this.path}/icon-search-solid-blue.svg`)) 
  .addSvgIcon("alert-solid", this.setPath(`${this.path}/alert-solid.svg`))
  .addSvgIcon("alert-base", this.setPath(`${this.path}/alert-base.svg`))
  .addSvgIcon("clock-base-teal", this.setPath(`${this.path}/clock-base-teal.svg`))
  .addSvgIcon("caret-left-teal", this.setPath(`${this.path}/caret-left-teal.svg`))
  .addSvgIcon("caret-right-teal", this.setPath(`${this.path}/caret-right-teal.svg`))
  .addSvgIcon("pie-portion", this.setPath(`${this.path}/pie-portion.svg`))
  .addSvgIcon("thumbs-up", this.setPath(`${this.path}/thumbs-up.svg`))
  .addSvgIcon("icon-information", this.setPath(`${this.path}/icon-information-solid-blue.svg`));
 }
//  filter_list
 private setPath(url: string): SafeResourceUrl {
  return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
 }
}
