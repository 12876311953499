<div *ngIf="currentUser" >
    <app-header [name]="fullName" [email]="emailAddress"></app-header>

    <div *ngIf="!isParUser" class="main-container">
      <app-unauthorized></app-unauthorized>
  </div>

    <div *ngIf="isParUser" class="main-container">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <app-global-loader></app-global-loader>

</div>
