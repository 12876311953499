import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderService {

  isLoading = new Subject<boolean>();

  show() {
    this.isLoading.next(true);
 }

 hide() {
    this.isLoading.next(false);
 }
}