import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  authorizedUserUrl =
    'https://forms.office.com/Pages/ResponsePage.aspx?id=OjjeLh9-V0OoRoWIaywMTexMKQcyl_JAs6uEpknbHkZUNzE2SjI2U01YSEE0OU82RFY3RkRCN1dCUyQlQCN0PWcu';

  ngOnInit(): void {
    this.nonUserRedirect();
  }

  nonUserRedirect() {
    window.open(this.authorizedUserUrl, '_blank');
  }

  nonUserClick(){
    window.open(this.authorizedUserUrl);
  }

}
