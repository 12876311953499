import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingCodesComponent } from './components/accounting-codes/accounting-codes.component';
import { MaterialModule } from '../material.module';
import { IconModule } from './icon/icon.module';
import { DeFooterModule, DeHeaderModule, DeButtonModule, DeListModule, DeLoaderModule, DeDatepickerModule, DeDrawerModule } from '@de-electron/electron-angular-components';
import { EmployeeSearchComponent } from './components/employee-search/employee-search.component';
import { ProjectSearchComponent } from './components/project-search/project-search.component';
import { DisplayAccountingCodesComponent } from './components/display-accounting-codes/display-accounting-codes.component';
import { RouterModule } from '@angular/router';
import { ParNotificationComponent } from './components/par-notification/par-notification.component';

export const deModules = [
  DeFooterModule,
  DeHeaderModule,
  DeButtonModule,
  DeListModule,
  DeLoaderModule,
  DeDatepickerModule,
  DeDrawerModule,
]

@NgModule({
  declarations: [
    AccountingCodesComponent,
    EmployeeSearchComponent,
    ProjectSearchComponent,
    DisplayAccountingCodesComponent,
    ParNotificationComponent,
    ],
  imports: [
    CommonModule,
    MaterialModule,
    IconModule,
    RouterModule,
    ...deModules
  ],
  exports: [
    AccountingCodesComponent,
    DisplayAccountingCodesComponent,
    EmployeeSearchComponent,
    ProjectSearchComponent,
    MaterialModule,
    IconModule,
    ...deModules
  ]
})
export class SharedModule { }
