// ---------------------
// Azure Variables
// ---------------------
const azureConfig = {
  apiBaseUrl: {
    local: 'https://portfolio-automation-reporting-api2-sbx.duke-energy.app',
    sbx: 'https://portfolio-automation-reporting-api2-sbx.duke-energy.app',
    dev: 'https://portfolio-automation-reporting-api2-dev.duke-energy.app',
    qa: 'https://portfolio-automation-reporting-api2-qa.duke-energy.app',
    prod: 'https://portfolio-automation-reporting-api2.duke-energy.app'
  },
  clientId: {
    sbx: 'db582718-d5fb-4207-bbf7-e9d70ac3b7e0',
    dev: '195b0d55-9e41-4b2f-b3ec-d3ad3e59f994',
    qa: 'bd8ebefc-a8c1-4bc5-a716-1c3fe70eeab2',
    prod: 'feb75d3a-a317-4e73-ae63-cf86d62c4ba7'
  },
  tenantId: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
  redirectUri: {
    local: 'http://localhost:4200/',
    sbx: 'https://portfolio-automation-reporting-web-sbx.duke-energy.app',
    dev: 'https://portfolio-automation-reporting-web-dev.duke-energy.app',
    qa: 'https://portfolio-automation-reporting-web-qa.duke-energy.app',
    prod: 'https://portfolio-automation-reporting-web.duke-energy.app'
  },
  api: {
    sbx: 'api://db582718-d5fb-4207-bbf7-e9d70ac3b7e0/default',
    dev: 'api://195b0d55-9e41-4b2f-b3ec-d3ad3e59f994/default',
    qa: 'api://bd8ebefc-a8c1-4bc5-a716-1c3fe70eeab2/default',
    prod: 'api://feb75d3a-a317-4e73-ae63-cf86d62c4ba7/default'
  }
}

// ---------------------
// Environment Objects
// ---------------------
export const environments = {
  local: {
    production: false,
    envName: 'local',
    apiBaseUrl: azureConfig.apiBaseUrl.sbx,
    azure_client_id: azureConfig.clientId.sbx,
    azure_tenant: azureConfig.tenantId,
    azure_redirect_uri: azureConfig.redirectUri.local,
    unauthorized_route: '/unauthorized',
    appRoles: ['par-users'],
    api: azureConfig.api.sbx
  },
  sbx: {
    production: false,
    envName: 'sbx',
    apiBaseUrl: azureConfig.apiBaseUrl.sbx,
    azure_client_id: azureConfig.clientId.sbx,
    azure_tenant: azureConfig.tenantId,
    azure_redirect_uri: azureConfig.redirectUri.sbx,
    unauthorized_route: '/unauthorized',
    appRoles: ['par-users'],
    api: azureConfig.api.sbx
  },
  dev: {
    production: false,
    envName: 'dev',
    apiBaseUrl: azureConfig.apiBaseUrl.dev,
    azure_client_id: azureConfig.clientId.dev,
    azure_tenant: azureConfig.tenantId,
    azure_redirect_uri: azureConfig.redirectUri.dev,
    unauthorized_route: 'unauthorized',
    appRoles: ['par-users'],
    api: azureConfig.api.dev
  },
  qa: {
    production: false,
    envName: 'qa',
    apiBaseUrl: azureConfig.apiBaseUrl.qa,
    azure_client_id: azureConfig.clientId.qa,
    azure_tenant: azureConfig.tenantId,
    azure_redirect_uri: azureConfig.redirectUri.qa,
    unauthorized_route: 'unauthorized',
    appRoles: ['par-users'],
    api: azureConfig.api.qa
  },
  prod: {
    production: true,
    envName: 'prod',
    apiBaseUrl: azureConfig.apiBaseUrl.prod,
    azure_client_id: azureConfig.clientId.prod,
    azure_tenant: azureConfig.tenantId,
    azure_redirect_uri: azureConfig.redirectUri.prod,
    unauthorized_route: 'unauthorized',
    appRoles: ['par-users'],
    api: azureConfig.api.prod
  }
};