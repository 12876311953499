import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ILoggerCallback } from '@azure/msal-common';
import { env } from 'src/config/env';
import { environments } from 'src/config/env.config';


export function MSALInstanceFactory(): IPublicClientApplication {
    console.log('%c Current ENV: '+ env.env, 'background: #222; color: #f542bf');
    return new PublicClientApplication({
        auth: {
            clientId: environments[env.env].azure_client_id,
            authority: 'https://login.microsoftonline.com/' + environments[env.env].azure_tenant,
            redirectUri: environments[env.env].azure_redirect_uri,
            postLogoutRedirectUri: environments[env.env].unauthorized_route,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: true, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    protectedResourceMap.set(environments[env.env].apiBaseUrl, [environments[env.env].api]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['User.Read'],
        },
        loginFailedRoute: environments[env.env].unauthorized_route,
    };
}

export const loggerCallback: ILoggerCallback = (level: number, message: string) => {
    // only used for setting up a callback
};
