import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  footerLinks = [
    {
      display: 'Duke Portal',
      href: 'https://dukeenergy.sharepoint.com/sites/portal',
    },
  ];
}
