import { createReducer, on } from "@ngrx/store";
import { initialDepartmentLeadsState } from "./department-leads.state";
import { addDeptLeads } from "./department-leads.action";

export const departmentLeadsReducer = createReducer(
    initialDepartmentLeadsState,
    on(addDeptLeads , (state , action) => (
        {
            ...state,
            departmentLeads :  action.departmentLeads
        }
    ))
)