import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DrawerGreeting,
  HeaderLink,
} from '@de-electron/electron-angular-components';
import { forkJoin } from 'rxjs';
import { WorkerInfo } from 'src/app/models/worker-info';
import { WorkerMytime } from 'src/app/models/worker-mytime';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthenticatedUser } from 'src/app/services/auth/authenticated-user';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() currentUser?: AuthenticatedUser;
  @Input() email: string;
  @Input() name: string;

  public headerLinks: HeaderLink[];
  bugReportURL: string;

  // Properties for hamburger menu drawer on mobile
  public headerDrawerIsOpen: boolean = false;
  public drawerGreeting: DrawerGreeting;
  public drawerLinks: HeaderLink[];
  public loginUserInfo: WorkerInfo;
  isHeaderTooSmall: boolean = false;
  myTimeValidated: boolean = true;
  contractorMyTimeAll: WorkerMytime[] = [];
  employeeMyTimeAll: WorkerMytime[] = [];

  constructor(
    private router: Router,
    private storage: StorageService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.loginUserInfo = this.storage.getLoggedUser()[0];
    this.buildHeaderLinks();
  }

  buildHeaderLinks() {
    this.headerLinks = [
      {
        viewValue: 'Dashboard',
        value: 'dashboard',
      },
      {
        viewValue: 'Workers',
        value: 'worker',
      },
      {
        viewValue: 'Products',
        value: 'projectDashboard',
      },
      {
        viewValue: 'Reports',
        value: '',
        subLinks: [
          { viewValue: 'MyTime Comparison', value: 'cap-om-report' },
          { viewValue: 'Worker Allocation Report', value: 'worker-allocation-report'},
        ],
      },

      {
        viewValue: `${this.name}`,
        value: 'user',
        showUserIcon: true,

        subLinks: [
          { viewValue: 'Admin', value: 'admin' },
          { viewValue: 'Report A Bug', value: 'bugReport' },
          { viewValue: 'Suggest A Feature', value: 'bugReport' },
          { viewValue: 'Help', value: 'help' },
        ],
      },
    ];

    this.drawerGreeting = {
      viewValue: `${this.name}`,
    };

    this.drawerLinks = [
      { viewValue: 'Home', value: 'dashboard' },
      { viewValue: 'Report A Bug', value: 'bugReport' },
    ];

    this.breakpointObserver
      .observe(['(min-width: 839px)'])
      .subscribe((state: BreakpointState) => {
        if(state.matches){
          this.isHeaderTooSmall = false;
        }
        else{
          this.isHeaderTooSmall = true;
        }
      });
  }

  onNavLinkClick(event) {
    if (event.value === 'bugReport') {
      this.bugReport();
    } else if (event.viewValue === 'Admin') {
      this.router.navigate(['admin']);
    }
      else if (event.viewValue === 'Help'){
        this.router.navigate(['help'])
      }
     else if (event.value === 'dashboard') {
      if (sessionStorage.getItem('Seat to update')) {
        sessionStorage.removeItem('Seat to update');
      }
      this.navigateHome();
    } else if (event.value === 'projectDashboard') {
      this.router.navigate(['project/dashboard']);
    } else if (event.value === 'cap-om-variance') {
      this.router.navigate(['reports/cap-om-variance']);
    } else if (event.value === 'mytime-assignment') {
      this.router.navigate(['reports/mytime-assignment']);
    } else if (event.value === 'cap-om-report') {
      this.router.navigate(['reports/cap-om-report']);
    } else if (event.value === 'worker-allocation-report') {
      this.router.navigate(['reports/worker-allocation-report'])
    } else if (event.value === 'newProject') {
      this.router.navigate(['project/new']);
    } else if (event.viewValue === 'Workers') {
      this.router.navigate(['worker']);
    }
  }

  bugReport() {
    this.bugReportURL =
      'https://forms.office.com/Pages/ResponsePage.aspx?id=OjjeLh9-V0OoRoWIaywMTexMKQcyl_JAs6uEpknbHkZUNEw5R0FIV0VRQllHT0ZOU0JIUUNUQlRPNSQlQCN0PWcu';
    window.open(this.bugReportURL, '_blank');
  }

  // signOut(): void {
  //   this.azureHelperService.logout();
  // }

  toggleHamburgerMenuDrawer() {
    if (this.headerDrawerIsOpen){
      this.headerDrawerIsOpen = false;
    }
    else{
      this.headerDrawerIsOpen = true;
    }
  }

  navigateHome() {
    this.router.navigate(['dashboard']);
  }
}
