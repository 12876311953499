import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkerInfo } from 'src/app/models/worker-info';
import { AppConfig } from 'src/app/config/app.config';
import { RequestData } from 'src/app/models/request-data';
import { StorageService } from '../storage.service';
import { AdminAccessService } from '../interceptor/admin-access.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl: string;
  private loggedUser: WorkerInfo;
  private deptData: RequestData;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
    private storageService: StorageService,
    private adminService: AdminAccessService,
  ) {
    this.apiUrl = this.appConfig.getConfig('apiBaseUrl');
  }

  public getItems = (route: string, data) => {
    let body = this.createReqBody(data, 'get');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public getWorkers = (route: string, data) => {
    let body = this.createReqBody(data, 'get_workers');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

   // Calling a function for data
   public getUDCItems = (data) => {
    let body = this.createReqBody({"udc_type": data}, "get");
    return this.http.post(this.apiUrl + "/udc", body);
  }

  public getProjectMyTimeReport = (data) => {
    let body = this.createReqBody(data, "get");
    return this.http.post(this.apiUrl + "/project-mytime", body);
  }

  public archiveProject = (data) => {
    let body = this.createReqBody(data, "delete");
    return this.http.post(this.apiUrl + "/project-archive", body);
  }

  public directorData = (data) => {
    let body = this.createReqBody(data, "get");
    return this.http.post(this.apiUrl + "/director-worker", body);
  }

  public directorProjectInfo = (data) => {
    let body = this.createReqBody(data, "get");
    return this.http.post(this.apiUrl + "/project-forecast", body);
  }

  public getDomainPortfolioData = (data) => {
    let body = this.createReqBody(data, "get");
    return this.http.post(this.apiUrl + "/groups", body);
  }

  public getGrouperPageDataForAdmin = (data) => {
    const body = this.createReqBody(data, 'get_admin_grouper')
    return this.http.post(this.apiUrl + "/groups", body);
  }

  public addGrouperPageDataForAdmin = (data) => {
    const body = this.createReqBody(data, 'add_admin_grouper')
    return this.http.post(this.apiUrl + "/groups", body);
  }

  public deleteGrouperPageDataForAdmin = (data) => {
    const body = this.createReqBody(data, 'delete_admin_grouper')
    return this.http.post(this.apiUrl + "/groups", body);
  }

  public searchItems = (route: string, data) => {
    // filter_value required in data to filter
    let body = this.createReqBody(data, 'search');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public filterItems = (route: string, data) => {
    // filter_value required in data to filter
    let body = this.createReqBody(data, 'filter');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public filterAccountingCodes = (route: string, data) => {
    let body = this.createReqBody(data, 'filter_accounting_assignments');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  }

  public blendedRate = (route: string, data) => {
    let body = this.createReqBody(data, 'blended-rate');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public selectivePayPeriod = (route: string, data) => {
    let body = this.createReqBody(data, 'get_selective_pay_period_end_dates');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  }

  public monthlyForecastEdit = (route: string, data) => {
    let body = this.createReqBody(data, 'monthly_forecast_edit');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  }

  public saveItems = (route: string, data) => {
    let body = this.createReqBody(data, 'post');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public reportRefresh = (route: string, data) => {
    let body = this.createReqBody(data, 'update');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public updateItem = (route: string, data) => {
    let body = this.createReqBody(data, 'update');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public updateLink = (route: string, data) => {
    let body = this.createReqBody(data, 'update_link');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body)
  }

  public deleteItem = (route: string, data) => {
    let body = this.createReqBody(data, 'delete');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public archiveItem = (route: string, data) => {
    let body = this.createReqBody(data, 'archive');
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  }

  public currentUserInfo = (route: string, data) => {
    const url = this.createCompleteRoute(route, this.apiUrl);
    return this.http.post(url, data);
  };

  public dashboardData = (
    route: string,
    report_type: string,
    data?: RequestData
  ) => {
    const body = this.dashboardRequestData(report_type, data);
    return this.http.post(this.createCompleteRoute(route, this.apiUrl), body);
  };

  public sendEmail = (path: string, data: any) => {
    return this.http.post(this.createCompleteRoute(path, this.apiUrl), data);
  };

  public preSignedURL = (reqObj: Object) => {
    const body = this.createReqBodyForUpload(reqObj, 'presigned-url-post');
    return this.http.post(this.createCompleteRoute('file', this.apiUrl), body);
  };

  public uploadFile = (res: any, reqData: Object, file: File) => {

    let fileName = reqData['file'];

    const formData = new FormData();
    formData.append('file', file, fileName);

    return this.http.put(res, formData, { headers: { skip: 'true' } });
  };

  private createReqBody(req: Object, method: string) {
    const deptData = this.addDepartmentData();
    const reqBody = {
      data: {
        ...req,
        ...deptData,
      },
      method: method,
    };
    return reqBody;
  }

  private createReqBodyForUpload(req: Object, method: string) {
    const reqBody = {
      data: {
        ...req,
      },
      method: method,
    };
    return reqBody;
  }

  private createReqBodyForPreSigned(req: Object, method: string) {
    const deptData = this.addDepartmentData();
    const reqBody = {
      data: {
        ...req,
        ...deptData,
      },
      method: method,
      files: req['fileContent'],
    };
    return reqBody;
  }

  //All request will contain these data
  private addDepartmentData(): RequestData {
    this.loggedUser = this.storageService.getLoggedUser();
    //checking if the user chose any value already from Admin Section's "Department Swap"
    const loggedStorageUser = this.storageService.getAdminDepartment();
    let adminUsers = this.adminService.getAdminUsers();
    // this just means that if the user has permissions for swapping departments, only then
    // you should use the localStorage's level_6 & level_7, else use the regular method for
    // getting the level_6 & level_7 IDs
    if(loggedStorageUser && adminUsers){
      this.deptData = {
        level6_source_department_id: loggedStorageUser['level6_source_department_id'],
        level7_source_department_id: loggedStorageUser['level7_source_department_id'],
      }
    }
    else{
      this.deptData = {
        level6_source_department_id:
          this.loggedUser[0].level6_source_department_id,
        level7_source_department_id:
          this.loggedUser[0].level7_source_department_id,
      }
    };
    return this.deptData;
  }

  // dashboard data
  private dashboardRequestData(type: string, data?: RequestData) {
    const depData = this.addDepartmentData();
    let body = {
      data: {
        ...depData,
        ...data,
      },
      report_type: type,
    };
    return body;
  }

  private createCompleteRoute = (route: string, envUrl: string) => {
    return `${envUrl}/${route}/`;
  };

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
}
