import { createReducer, on } from "@ngrx/store";
import { initialProjectState } from "./project.state";
import { addProject } from "./project.action";

export const projectReducer = createReducer(
    initialProjectState,
    on(addProject , (state , action) => (
        {
            ...state,
            project :  action.project
        }
    ))
)