import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AccountInfo } from '@azure/msal-browser';
import { AuthenticatedUser } from './authenticated-user';
import { AzureHelperService } from './azure-helper.service';
import { ApiService } from '../api/api.service';
import { StorageService } from '../storage.service';
import { WorkerInfo } from 'src/app/models/worker-info';
import { env } from '../../../config/env';
import { AdminAccessService } from '../interceptor/admin-access.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UnauthorizedComponent } from 'src/app/shared/components/unauthorized/unauthorized.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public userEmail: string;
  public auth: any;
  private _currentUser$: BehaviorSubject<AuthenticatedUser> =
    new BehaviorSubject(null);
  // public currentUser$: Observable<AuthenticatedUser> = this._currentUser$.asObservable();
  parAdminArr: string[];
  currentEnv = env.env;

  constructor(
    private azureService: AzureHelperService,
    private api: ApiService,
    private storage: StorageService,
    private adminService: AdminAccessService,
    private dialog: MatDialog,
  ) {
    this.azureService
      .getActiveAccountObservable()
      .pipe(
        filter(
          (accountInfo: AccountInfo) =>
            accountInfo != null && accountInfo.username != null
        )
      )
      .subscribe((accountInfo: AuthenticatedUser) => {
        
        try {
          // user has the required permissions
          this._currentUser$.next(accountInfo);
          console.log('Is Admin...', this.isAdmin());
          console.log('Is PAR User...', this.isParUser());
          this.loggedIn$.next(true);
        } catch (error) {
          // user doesn't have the required permissions
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          this.dialog.open(UnauthorizedComponent, dialogConfig);
        }
        
      });
  }

  getCurrentAuthenticatedUser(): Observable<AuthenticatedUser> {
    return this._currentUser$.pipe(filter((user) => !!user));
  }

  async getUserEmail(): Promise<string> {
    return this.getCurrentAuthenticatedUser()
      .pipe(
        map((user: AuthenticatedUser) => user.username),
        take(1)
      )
      .toPromise();
  }

  async getUsersName(): Promise<string> {
    return this.getCurrentAuthenticatedUser()
      .pipe(
        map((user: AuthenticatedUser) => user.name),
        take(1)
      )
      .toPromise();
  }

  async getLoggedUserInfo(email: string) {
    this.api
      .currentUserInfo('userinfo', { email: email })
      .subscribe((res: WorkerInfo) => {
        this.storage.setLoggedUser(res);
      });
  }

  getLoggedUserInfo$(email: string) {
    return this.api
    .currentUserInfo('userinfo', { email: email })
    .pipe(map((res: WorkerInfo) => {
      this.storage.setLoggedUser(res);
      return res;
    }));
}

  public isAdmin() {
    const currentUser = this._currentUser$.getValue();
    const userGroups = currentUser.idTokenClaims.roles;
    return userGroups.includes('par-admin');
  }

   public isDirector() {
    const currentUser = this._currentUser$.getValue();
    const userGroups = currentUser.idTokenClaims.roles;
    return  userGroups.includes('par-director')
  }

  public PARFinancials() {
    // executing different permissions(through Azure's AD groups) for PROD and NON-PROD environments
    if(this.currentEnv.toLocaleLowerCase() == 'prod') {
      const currentUser = this._currentUser$.getValue();
      const userGroups = currentUser.idTokenClaims.roles;
      return userGroups.includes('par-financials') ||
             // adding this line(developer permissions) for testing purposes. will remove later if not required
             this.adminService.getAdminUsers().includes(this._currentUser$.getValue().username.toLocaleLowerCase())
    } else {
      const currentUser = this._currentUser$.getValue();
      const userGroups = currentUser.idTokenClaims.roles;
      return userGroups.includes('par-financials')
    }  
  }

  public isParUser() {
    const currentUser = this._currentUser$.getValue();
    const userGroups = currentUser.idTokenClaims.roles;
    return userGroups.includes('par-users');
  }

  
}
