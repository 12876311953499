import { JsonPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfig } from '../config/app.config';
import { DeptLeads } from '../models/dept-leads';
import { Project } from '../models/project';
import { WorkerInfo } from '../models/worker-info';
import { AdminDepartment } from '../models/admin-department';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private apiUrl: string;

  // selected worker observable
  private workerDataSrc = new BehaviorSubject<WorkerInfo[]>(null);
  selectedWorker = this.workerDataSrc.asObservable();

  // selected project observable
  private projectDataSrc = new BehaviorSubject<Project[]>(null);
  selectedProject = this.projectDataSrc.asObservable();

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.apiUrl = this.appConfig.getConfig('api_gateway_url');
  }

  public setCurrentProject(data: Project[]) {
    this.projectDataSrc.next(data);
  }

  public setSelectedWorkerInfo(data: WorkerInfo[]) {
    this.workerDataSrc.next(data);
  }

  public setCurrentUser(user: AccountInfo): void {
    if (user) {
      localStorage.setItem('current_user', JSON.stringify(user)); // current active azure user
    }
  }

  public getCurrentUser(): AccountInfo {
    let user = localStorage.getItem('current_user'); // azure user
    if (user) {
      return JSON.parse(user);
    }
  }

  public setCurrentManager(source_worker_id: string) {
    
    if (source_worker_id) {
      localStorage.setItem('current_manager', source_worker_id);
    }
  }

  public getCurrentManager(): string {
    let current_manager = localStorage.getItem('current_manager');
    if (current_manager) {
      return current_manager;
    }
  }

  public setLoggedUser(user: WorkerInfo): void {
    if (user) {
      localStorage.setItem('user_info', JSON.stringify(user)); // login user information
    }
  }

  public getLoggedUser(): WorkerInfo {
    let user = localStorage.getItem('user_info'); // current logged in user
    if (user) {
      return JSON.parse(user);
    }
  }

  public setManagerList(list: DeptLeads[]): void {
    localStorage.setItem('manager_list', JSON.stringify(list));
  }

  public getManagerList(): DeptLeads[] {
    if (!localStorage.getItem('manager_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('manager_list'));
    }
  }

  public setDeptLeadList(list: DeptLeads[]): void {
    localStorage.setItem('deptLead_list', JSON.stringify(list));
  }

  public getDeptLeadList(): DeptLeads[] {
    if (!localStorage.getItem('deptLead_list')) {
      // don't send 'null' value as the UI will break if no 'Dept. Leads' are selected. Sending an empty list instead.
      return [];
    } else {
      return JSON.parse(localStorage.getItem('deptLead_list'));
    }
  }

  public setProjectList(list: Project[]): void {
    localStorage.setItem('project_list', JSON.stringify(list));
  }

  public getProjectList(): Project[] {
    if (!localStorage.getItem('project_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('project_list'));
    }
  }

  public setnavigationList(history: string){
    if (!localStorage.getItem('navigation_page')){
      localStorage.setItem("navigation_page", JSON.stringify([]));
    }
    else{
      const navList = JSON.parse(localStorage.getItem("navigation_page"));
      navList.push(history);
      localStorage.setItem("navigation_page", JSON.stringify(navList));
    }   
  }

  public getnavigationList(){
      if(!localStorage.getItem("navigation_page")){
        return null;
      }
      else{
          return JSON.parse(localStorage.getItem("navigation_page"));   
      }
  }
  
  public setDomainList(list: Project[]): void {
    localStorage.setItem('domain_list', JSON.stringify(list));
  }

  public getDomainList(): Project[] {
    if (!localStorage.getItem('domain_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('domain_list'));
    }
  }

  public setPortfolioList(list: Project[]): void {
    localStorage.setItem('portfolio_list', JSON.stringify(list));
  }

  public getPortfolioList(): Project[] {
    if (!localStorage.getItem('portfolio_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('portfolio_list'));
    }
  }

  public setAvailabilityList(list: Project[]): void {
    localStorage.setItem('availability_list', JSON.stringify(list));
  }

  public getAvailablityList(): Project[] {
    if (!localStorage.getItem('availability_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('availability_list'));
    }
  }

  public setSpendList(list: Project[]): void {
    localStorage.setItem('spend_list', JSON.stringify(list));
  }

  public getSpendList(): Project[] {
    if (!localStorage.getItem('spend_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('spend_list'));
    }
  }

  public setAIMLComponentList(list: Project[]): void {
    localStorage.setItem('aiml_component_list', JSON.stringify(list))
  }

  public getAIMLComponentList(): Project[] {
    if (!localStorage.getItem('aiml_component_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('aiml_component_list'));
    }
  }

  public setonAWSComponentList(list: Project[]): void {
    localStorage.setItem('on_aws_list', JSON.stringify(list))
  }

  public getonAWSComponentList(): Project[] {
    if (!localStorage.getItem('on_aws_list')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('on_aws_list'));
    }
  }

  //this is used to hold the selected department leads on the product
  //and or worker pages.
  public setSelectedDeptLeadList(list: string[]): void {
    localStorage.setItem('selected_deptLead_list', JSON.stringify(list));
  }

  public getSelectedDeptLeadList(): string[] {
    if (!localStorage.getItem('selected_deptLead_list')) {
      // sending an empty list here since if a 'null' value gets sent up, it can break the UI
      return [];
    } else {
      return JSON.parse(localStorage.getItem('selected_deptLead_list'));
    }
  }
  
  public setWorkerType(value: string): void {
    localStorage.setItem('worker_type', value);
  }

  public getWorkerType(): string {
    if (!localStorage.getItem('worker_type')) {
      return null;
    } else {
      return localStorage.getItem('worker_type');
    }
  }

  public setAdminDepartment(value: AdminDepartment): void {
    localStorage.setItem('admin_department', JSON.stringify(value));
  }

  public getAdminDepartment(): AdminDepartment {
    if (!localStorage.getItem('admin_department')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('admin_department'));
    }
  }

  public clearAdminDepartment(){
    localStorage.removeItem("admin_department");
  }
}
